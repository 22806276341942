/*.containercomercios {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}*/

/*********************fondo*/

.comerciosintro {
  width: 100%;
  height: 100%;
  background: url(./comercios.jpg) center center no-repeat;
  /*background-color: #e5e5e5;*/
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}


/*.comerciosintro .overlaycomercios {
  background: rgba(0, 0, 0, 0.2);
}*/
.comerciosintro h1 {
  font-family: "Raleway", sans-serif;
  color: #fff;
  font-size: 50px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 10px;
}

.comerciosintro h1 span {
  font-weight: 800;
  color: #5ca9fb;
}

.comerciosintro p {
  color: #fff;
  font-size: 35px;
  font-weight: 300;
  line-height: 30px;
  margin: 0 auto;
  margin-bottom: 60px;
}

#comercios .comerciosintro-text {
  padding-top: 350px;
  padding-bottom: 200px;
  text-align: center;
}


/*********************titulo*/
.titulocomercios {
  text-align: center;
  padding-top: 100px;
}

.titulocomercios h2 {
  color: rgb(71, 71, 71);
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.titulocomercios h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}

.titulocomercios p {
  font-size: 18px;
}

.rowcomercios {
  width: 100%;
  position: relative;
  padding-top: 100px;
  text-align: center;
  /*float: left;
  margin-right: -15px;
  margin-left: -15px;*/
}

@media (min-width: 968px) {
  .rowcomercios {
    justify-content: center;
    display: flex;
  }

  #botonCentradoAzul {
    margin-top: 100px;
    margin-right: 10px;
    margin-left: 10px;
    height: 150px;
    width: 25%;

  }
}


@media (max-width: 967px) {
  #botonCentradoAzul  {
    width: 90%;
    margin-bottom: 10px;
    height: 15%;
  }
}

#botonCentradoAzul {
  background: linear-gradient(to right, #0075bf 0%, #26abff 100%);

  float: left;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 7px;
  position: relative;
  left: 0%;
  top: 50%;
  transform: 'translate(-20%, -50%)';
}

#botonCentradoAzul2 {
  font-size: 18px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border-radius: 7px;
  position: absolute;
  left: 5%;
  top: 10%;
  transform: 'translate(-50%, -50%)';
  width: 50%;
  height: 60%;
  float: left;
}

#botonCentradoAzul3 {
  width: 40%;
  font-size: 18px;
  color: white;
  float: right;
  text-align: left;
  font-weight: bold;
  margin-top: 10px;
}
