/*.containerEntidades {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}*/

/*********************fondo*/

.entidadesintro {
  width: 100%;
  height: 100%;
  background: url(./entidades.jpg) center center no-repeat;
  /*background-color: #e5e5e5;*/
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}


/*.entidadesintro .overlayEntidades {
  background: rgba(0, 0, 0, 0.2);
}*/
.entidadesintro h1 {
  font-family: "Raleway", sans-serif;
  color: #fff;
  font-size: 50px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 10px;
}

.entidadesintro h1 span {
  font-weight: 800;
  color: #5ca9fb;
}

.entidadesintro p {
  color: #fff;
  font-size: 35px;
  font-weight: 300;
  line-height: 30px;
  margin: 0 auto;
  margin-bottom: 60px;
}

#entidades .entidadesintro-text {
  padding-top: 350px;
  padding-bottom: 200px;
  text-align: center;
}


/*********************titulo*/
.tituloEntidades {
  text-align: center;
  padding-top: 100px;
}

.tituloEntidades h2 {
  color: white;
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.tituloEntidades h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}

.tituloEntidades p {
  font-size: 18px;
}

.rowEntidades {
  width: 100%;
  position: relative;
  padding-top: 100px;
  text-align: center;
  /*float: left;
  margin-right: -15px;
  margin-left: -15px;*/
}

@media (min-width: 968px) {
  .rowEntidades {
    justify-content: center;
    display: flex;
  }

  #botonCentradoAzulIzquierda, #botonCentradoAzulDerecha  {
    margin-top: 100px;
    margin-right: 10px;
    margin-left: 10px;
  }
}


@media (max-width: 967px) {
  #botonCentradoAzulIzquierda, #botonCentradoAzulDerecha  {
    width: 90%;
    margin-bottom: 10px;
  }
}

#botonCentradoAzulIzquierda {
  
  float: left;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 7px;
  position: relative;
  left: 0%;
  top: 50%;
  transform: 'translate(-20%, -50%)';
  height: 25%;
}

#botonCentradoAzulIzquierda2 {
  font-size: 18px;
  color: white;
  background: linear-gradient(to right, #0075bf 0%, #26abff 100%);
  border-radius: 7px;
  position: absolute;
  left: 5%;
  top: 10%;
  transform: 'translate(-50%, -50%)';
  width: 50%;
  height: 60%;
  float: left;
}

#botonCentradoAzulIzquierda3 {
  width: 40%;
  font-size: 18px;
  color: white;
  float: right;
  text-align: left;
  font-weight: bold;
  margin-top: 10px;
}


#botonCentradoAzulDerecha {
  float: left;
  padding: 20px;

  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 7px;
  position: relative;
  left: 0%;
  top: 50%;
  transform: 'translate(-50%, -50%)';
  height: 25%;
}

#botonCentradoAzulDerecha2 {
  font-size: 18px;
  color: white;
  background: linear-gradient(to right, #0075bf 0%, #26abff 100%);
  border-radius: 7px;
  position: absolute;
  left: 5%;
  top: 10%;
  transform: 'translate(-50%, -50%)';
  width: 50%;
  height: 60%;
  float: left;
}

#botonCentradoAzulDerecha3 {
  width: 40%;
  font-size: 18px;
  color: white;
  float: right;
  text-align: left;
  font-weight: bold;
  margin-top: 10px;
}